import {
  TOB_QUINELLA,
  TOB_EXACTA_MULTI,
  TOB_WIDE,
  TOB_TRIO,
  TOB_TRIFECTA,
  TOB_BRACKET,
  TOB_BRACKET_EXACTA,
  TOB_WIN,
  TOB_PLACE,
  TOB_BRACKET_EXACTA_MULTI,
  TOB_EXACTA,
  TOB_TRIFECTA_MULTI,
  METHOD_NORMAL,
  METHOD_WHEEL,
  METHOD_WHEEL_1,
  METHOD_WHEEL_2,
  METHOD_WHEEL_3,
  METHOD_BOX,
  METHOD_WHEEL_1_2,
  METHOD_WHEEL_1_3,
  METHOD_WHEEL_2_3, METHOD_FORMATION,
} from '../../assets/js/define';
import {isCoupleBracketNum, isIphoneOrIsAndroid} from '../../assets/js/common';
import moment from 'moment';

const SPAT4_PC_PAGE_ID = 'N130S'
const SPAT4_PHONE_PAGE_ID = 'NS30S'
const SPAT4_CLIENT_ID = '0004'
const SPAT4_HOST = 'https://www.spat4.jp/'

export default function () {
  const setSpat4Bet = (object) => {
    const {dor, racetrackCd, raceNum, tob, method, bet, total, amount, entryHorseNum} = object;
    const spat4RacetrackCd = convertRacetrackCd(racetrackCd);
    const spat4TOB = convertTOB(tob);
    const spat4Method = convertMethod(method, tob);
    const multi = isMulti(tob) ? '1' : '0';
    const spat4Bet = convertSpat4Bet(bet, tob, method, entryHorseNum);
    const totalString = padToDigits(total);
    const spat4Amount = padToDigits(amount);
    const isPreSale = isPreSaleRace(dor);
    return `${dor}${spat4RacetrackCd}${raceNum}${isPreSale}_${spat4TOB}${spat4Method}${multi}_${spat4Bet}_${totalString}_${spat4Amount}`;
  };
  const convertRacetrackCd = (racetrackCd) => {
    const raceTrackCdObject = {
      '30': '36',
      '31': '01',
      '32': '02',
      '33': '03',
      '34': '04',
      '35': '10',
      '36': '11',
      '37': '12',
      '38': '14',
      '39': '15',
      '40': '16',
      '41': '17',
      '42': '18',
      '43': '19',
      '44': '20',
      '45': '21',
      '46': '22',
      '47': '23',
      '48': '24',
      '49': '26',
      '50': '27',
      '51': '28',
      '52': '29',
      '53': '30',
      '54': '31',
      '55': '32',
      '56': '33',
      '57': '34',
    };
    return raceTrackCdObject[racetrackCd];
  }
  const convertTOB = (tob) => {
    switch (tob) {
      case TOB_WIN:
      case TOB_PLACE:
      case TOB_BRACKET:
        return tob;
      case TOB_BRACKET_EXACTA:
      case TOB_BRACKET_EXACTA_MULTI:
        return '4';
      case TOB_QUINELLA:
        return '5';
      case TOB_EXACTA:
      case TOB_EXACTA_MULTI:
        return '6';
      case TOB_WIDE:
        return '7';
      case TOB_TRIO:
        return '8';
      case TOB_TRIFECTA:
      case TOB_TRIFECTA_MULTI:
        return '9';
    }
  }
  const convertMethod = (method, tob) => {
    switch (method) {
      case METHOD_NORMAL:
        return '0';
      case METHOD_WHEEL:
      case METHOD_WHEEL_1:
        return '1';
      case METHOD_WHEEL_2:
        if (tob === TOB_TRIO) {
          return '6';
        }
        return '2';
      case METHOD_BOX:
        if ([TOB_BRACKET_EXACTA, TOB_EXACTA, TOB_TRIFECTA].includes(tob)) {
          return '4';
        }
        return '3';
      case METHOD_WHEEL_3:
        return '5';
      case METHOD_WHEEL_1_2:
        return '6';
      case METHOD_WHEEL_1_3:
        return '7';
      case METHOD_WHEEL_2_3:
        return '8';
      case METHOD_FORMATION:
        return '9';
    }
  }
  const isMulti = (tob) => {
    return [TOB_EXACTA_MULTI, TOB_TRIFECTA_MULTI, TOB_BRACKET_EXACTA_MULTI].includes(tob);
  }
  const isBracketAndFormation = (tob, method) => {
    return [TOB_BRACKET, TOB_BRACKET_EXACTA].includes(tob) && method === METHOD_FORMATION;
  }
  const isTrioAndWheel2 = (tob, method) => {
    return [TOB_TRIO].includes(tob) && method === METHOD_WHEEL_2;
  }
  const convertSpat4Bet = (bet, tob, method, entryHorseNum) => {
    let spat4Bet = '';
    let paddedString = bet;

    if (isBracketAndFormation(tob, method)) {
      paddedString += padCoupleBet(bet, entryHorseNum);
    } else if (isTrioAndWheel2(tob, method)) {
      paddedString = convertTrioAndWheel2Bet(bet);
    } else {
      paddedString = bet.padEnd(54, '0');
    }

    for (let i = 0; i < 3; i++) {
      let start = i * 18;
      let end = start + 18;
      let part = paddedString.substring(start, end);

      spat4Bet += encodeBetToHex(part);
    }

    return spat4Bet;
  }
  const padCoupleBet = (bet, entryHorseNum) => {
    let firstHalf = bet.substring(0, 18);
    let secondHalf = bet.substring(18, 36);

    let coupleBet = '';

    for (let i = 0; i < 18; i++) {
      const bracketNum = i + 1;
      if (firstHalf[i] === '1' && secondHalf[i] === '1' && isCoupleBracketNum(entryHorseNum, bracketNum)) {
        coupleBet += '1';
      } else {
        coupleBet += '0';
      }
    }

    return coupleBet;
  }
  const convertTrioAndWheel2Bet = (bet) => {
    const firstHalf = bet.slice(0, 18);
    const secondHalf = bet.slice(18);

    const positionArray = []
    for (let i = 0; i < firstHalf.length; i++) {
      if (firstHalf[i] === '1') {
        positionArray.push(i);
      }
    }

    let convertBet = '';
    for (let i = 0; i < positionArray.length; i++) {
      for (let j = 0; j < 18; j++) {
        if (positionArray[i] === j) {
          convertBet += '1';
        } else {
          convertBet += '0';
        }
      }
    }

    return convertBet + secondHalf;
  }
  const encodeBetToHex = (betString) => {
    const trimmedBetString = betString.substring(0, 16);
    if (trimmedBetString === '0000000000000000') {
      return '0000'
    }
    const encodeBet = parseInt(trimmedBetString, 2).toString(16).toUpperCase();
    return padToDigits(encodeBet, 4);
  }
  const padToDigits = (num, length = 4) => {
    return (num + '').padStart(length, '0');
  }
  const getSpat4ApiUrl = async () => {
    const {isIphone, isAndroid} = await isIphoneOrIsAndroid();
    const handler = (isIphone || isAndroid) ? SPAT4_PHONE_PAGE_ID : SPAT4_PC_PAGE_ID;

    return `${SPAT4_HOST}keiba/pc?HANDLERR=${handler}&WDR=${SPAT4_CLIENT_ID}`;
  }
  const isPreSaleRace = (dor) => {
    const raceDate = moment(dor, 'YYYYMMDD');
    return moment().add(1, 'days').isSame(raceDate, 'day') ? '1' : '0';
  }
  return {
    setSpat4Bet,
    padToDigits,
    getSpat4ApiUrl,
  };
}
